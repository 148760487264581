import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const Header = ({
  size,
  text,
  align,
  color,
  headerStyle,
  marginTop,
  marginBottom,
  className,
}) => {
  const block = "maars-header"
  const HeaderTag = size || "h2"
  return (
    <HeaderTag
      className={classNames(
        block,
        `${block}__style-${headerStyle}`,
        `${block}__color-${color}`,
        `${block}__align-${align}`,
        { [`${block}__margin-top-${marginTop}`]: marginTop },
        { [`${block}__margin-bottom-${marginBottom}`]: marginBottom },
        className
      )}
    >
      {text}
    </HeaderTag>
  )
}

Header.propTypes = {
  size: PropTypes.string,
  headerStyle: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  className: PropTypes.string,
}

Header.defaultProps = {
  size: "h2",
  headerStyle: "h2",
  text: "",
  color: "black",
  align: "left",
  marginTop: "none",
  marginBottom: "none",
  className: "",
}

export default Header
