import React from "react"
import { useSpring, animated } from "react-spring"
import { FaTimes } from "react-icons/fa"
import Info from "./partials/info"
import About from "./partials/about"
import Menu from "./partials/menu"

export default function AnimatedOverlay({ overlay, closeOverlay }) {
  const block = "maars-overlay"

  const aboutAnimation = useSpring({
    transform: overlay === "about" ? `translateX(0)` : `translateY(100%)`,
    opacity: overlay === "about" ? 1 : 0,
  })

  const menuAnimation = useSpring({
    transform: overlay === "menu" ? `translateY(0)` : `translateX(-100%)`,
    opacity: overlay === "menu" ? 1 : 0,
  })

  const infoAnimation = useSpring({
    transform: overlay === "info" ? `translateX(0)` : `translateX(100%)`,
    opacity: overlay === "info" ? 1 : 0,
  })

  const renderCloseButton = overlay => (
    <div className={`${block}__close-button`}>
      <span className="sr-only" hidden={true}>
        Close
      </span>
      <FaTimes
        onClick={() => {
          closeOverlay()
        }}
      />
    </div>
  )

  return (
    <React.Fragment>
      <animated.div className={block} style={infoAnimation}>
        {renderCloseButton(overlay)}
        <div className={`${block}__wrapper`}>
          <Info />
        </div>
      </animated.div>

      <animated.div className={block} style={aboutAnimation}>
        {renderCloseButton(overlay)}
        <div className={`${block}__wrapper`}>
          <About />
        </div>
      </animated.div>

      <animated.div className={block} style={menuAnimation}>
        {renderCloseButton(overlay)}
        <div className={`${block}__wrapper`}>
          <Menu />
        </div>
      </animated.div>
    </React.Fragment>
  )
}
