import React from "react"
import { useStaticQuery } from "gatsby"
import { RichText } from "prismic-reactjs"
import linkResolver from "../../../utils/linkResolver"

const parseMenuData = data => {
  const burgerSectionTitle =
    data.burgers_section_title && data.burgers_section_title.text
  const sidesSectionTitle =
    data.sides_section_title && data.sides_section_title.text
  const beerSectionTitle =
    data.beer_section_title && data.beer_section_title.text
  const wineSectionTitle =
    data.wine_section_title && data.wine_section_title.text

  const burgers =
    data.burgers &&
    data.burgers.map(({ title, description, price }) => {
      return {
        title: title.text,
        description: description.text,
      }
    })

  const sides =
    data.sides &&
    data.sides.map(({ title, description }) => {
      return {
        title: title.text,
        description: description.raw,
      }
    })

  const beers =
    data.beers &&
    data.beers.map(
      ({ title, description, abv, ibu, hops, colour, malts, price }) => {
        return {
          title: title.text,
          description: description.text,
          ibu,
          abv,
          malts,
          hops,
          colour,
          price,
        }
      }
    )

  const wines =
    data.wines &&
    data.wines.map(({ title, description }) => {
      return {
        title: title.text,
        description: description.raw,
      }
    })

  return {
    burgerSectionTitle,
    sidesSectionTitle,
    beerSectionTitle,
    wineSectionTitle,
    burgers,
    sides,
    beers,
    wines,
  }
}

const Menu = () => {
  const { prismicMenu } = useStaticQuery(
    graphql`
      query MenuQuery {
        prismicMenu {
          data {
            beer_section_title {
              text
            }
            burgers_section_title {
              text
            }
            sides_section_title {
              text
            }
            wine_section_title {
              text
            }
            burgers {
              description {
                text
              }
              title {
                text
              }
            }
            sides {
              description {
                raw
              }
              title {
                text
              }
            }
            beers {
              title {
                text
              }
              description {
                text
              }
              abv
              ibu
              colour
              hops
              malts
              price
            }
            wines {
              title {
                text
              }
              description {
                raw
              }
            }
          }
        }
      }
    `
  )

  const {
    burgerSectionTitle,
    sidesSectionTitle,
    beerSectionTitle,
    wineSectionTitle,
    burgers,
    sides,
    beers,
    wines,
  } = parseMenuData(prismicMenu.data)

  const block = "maars-menu"
  return (
    <div className={block}>
      <div className={`${block}__menu-wrapper`}>
        {burgers && burgers.length && (
          <div className={`${block}__section-container`}>
            <div className={`${block}__section-title`}>
              {burgerSectionTitle}
            </div>
            <div className={`${block}__menu-items-container`}>
              {burgers.map(({ title, description }) => {
                return (
                  <div
                    className={`${block}__menu-item ${block}__menu-item--col-2`}
                    key={title}
                  >
                    <div className={`${block}__menu-item-title`}>{title}</div>
                    <div className={`${block}__menu-item-description`}>
                      {description}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        )}

        {sides && sides.length > 0 && (
          <div className={`${block}__section-container`}>
            <div className={`${block}__section-title`}>{sidesSectionTitle}</div>
            <div className={`${block}__menu-items-container`}>
              {sides &&
                sides.map(({ title, description }) => {
                  return (
                    <div
                      className={`${block}__menu-item ${block}__menu-item--col-3`}
                      key={title}
                    >
                      <div className={`${block}__menu-item-title`}>{title}</div>
                      <div className={`${block}__menu-item-description`}>
                        <RichText
                          render={description}
                          linkResolver={linkResolver}
                        />
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        )}

        {beers && beers.length > 0 && (
          <div className={`${block}__section-container`}>
            <div className={`${block}__section-title`}>{beerSectionTitle}</div>
            <div className={`${block}__menu-items-container`}>
              {beers.map(
                ({
                  title,
                  description,
                  ibu,
                  abv,
                  malts,
                  hops,
                  colour,
                  price,
                }) => {
                  return (
                    <div
                      className={`${block}__menu-item ${block}__menu-item--col-2`}
                      key={title}
                    >
                      <div className={`${block}__menu-item-title`}>{title}</div>
                      <div className={`${block}__beer-abv-ibu`}>
                        <span>ABV: {abv}%</span>
                        <span>IBU: {ibu}</span>
                      </div>
                      {description && (
                        <div className={`${block}__beer-detail`}>
                          {description}
                        </div>
                      )}
                      {hops && (
                        <div className={`${block}__beer-detail`}>
                          Hops: {hops}
                        </div>
                      )}

                      {malts && (
                        <div className={`${block}__beer-detail`}>
                          Malts: {malts}
                        </div>
                      )}

                      {colour && (
                        <div className={`${block}__beer-detail`}>
                          Colour: {colour}
                        </div>
                      )}

                      {price && (
                        <div className={`${block}__beer-detail`}>{price}</div>
                      )}
                    </div>
                  )
                }
              )}
            </div>
          </div>
        )}

        {wines && wines.length > 0 && (
          <div className={`${block}__section-container`}>
            <div className={`${block}__section-title`}>{wineSectionTitle}</div>
            <div className={`${block}__menu-items-container`}>
              {wines &&
                wines.map(({ title, description }) => {
                  return (
                    <div
                      className={`${block}__menu-item ${block}__menu-item--col-2`}
                      key={title}
                    >
                      <div className={`${block}__menu-item-title`}>{title}</div>
                      <div className={`${block}__menu-item-description`}>
                        <RichText
                          render={description}
                          linkResolver={linkResolver}
                        />
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Menu
