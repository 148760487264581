import React from "react"
import PropTypes from "prop-types"

const Background = ({ children, containerClassName }) => {
  const block = "maars-background"

  return (
    <div className={block}>
      <div className={`${block}__stars`} />
      <div className={`${block}__twinkling`} />
      <div className={containerClassName}>{children}</div>
    </div>
  )
}

Background.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  containerClassName: PropTypes.string,
}

Background.defaultProps = {}

export default Background
