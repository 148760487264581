import React, { Component } from "react"
import { FaTimes } from "react-icons/fa"
import Info from "./partials/info"
import About from "./partials/about"
import Menu from "./partials/menu"

export default class Overlay extends Component {
  renderOverlay = overlay => {
    switch (overlay) {
      case "info":
        return <Info />
      case "menu":
        return <Menu />
      case "about":
        return <About />
      default:
        return false
    }
  }

  render() {
    if (this.props.overlay) {
      let overlayToRender = this.renderOverlay(this.props.overlay)
      if (!overlayToRender) return false
      const block = "maars-overlay"
      return (
        <div className={block}>
          <div className={`${block}__close-button`}>
            <span className="sr-only" hidden={true}>
              Close
            </span>
            <FaTimes
              onClick={() => {
                this.props.closeOverlay()
              }}
            />
          </div>
          <div className={`${block}__wrapper`}>{overlayToRender}</div>
        </div>
      )
    }
  }
}
