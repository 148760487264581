import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, Text, Header } from "components"

const AnnouncementModal = ({ title, message, link, linkText }) => {
  const block = "maars-announcement-modal"
  const [modalIsOpen, closeModal] = useState(true)

  return (
    <React.Fragment>
      {modalIsOpen && (
        <div className={block}>
          <div className={`${block}__modal`}>
            <div className={`${block}__title`}>
              <Header
                size="h2"
                headerStyle="h2"
                align="center"
                color="black"
                text={title}
                marginBottom="small"
              ></Header>
            </div>

            <Text
              size="medium"
              color="black"
              align="center"
              marginBottom="small"
              text={message}
            />

            {link && linkText ? (
              <div className={`${block}__button-group`}>
                <Button
                  className="annoucement-modal-button"
                  buttonStyle="primary-light"
                  label="Close"
                  onClick={() => closeModal(false)}
                />
                <Button
                  className="annoucement-modal-button"
                  buttonStyle="primary-light"
                  label={linkText}
                  link={link}
                />
              </div>
            ) : (
              <Button
                className="annoucement-modal-button"
                buttonStyle="primary-light"
                label="close"
                onClick={() => closeModal(false)}
              />
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

AnnouncementModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  link: PropTypes.string,
  linkText: PropTypes.string,
}

AnnouncementModal.defaultProps = {
  title: "",
  message: "",
  link: "",
  linkText: "",
}

export default AnnouncementModal
