import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Button = ({ buttonStyle, label, link, onClick, className, children }) => {
  const block = "maars-button"

  if (link) {
    return (
      <OutboundLink
        className={classNames(
          block,
          className,
          `${block}__style-${buttonStyle}`
        )}
        href={link}
      >
        {label}
        {children || ""}
      </OutboundLink>
    )
  } else {
    return (
      <button
        className={classNames(
          block,
          className,
          `${block}__style-${buttonStyle}`
        )}
        onClick={() => {
          onClick()
        }}
      >
        {label}
        {children || ""}
      </button>
    )
  }
}

Button.propTypes = {
  buttonStyle: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

Button.defaultProps = {
  buttonStyle: "primary-light",
  text: "",
  link: "",
  onClick: () => {},
  classNames: "",
}

export default Button
