import React from "react"
import PropTypes from "prop-types"
import { FaFacebook, FaInstagram, FaMapMarkerAlt } from "react-icons/fa"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Footer = ({ facebookUrl, instagramUrl, googleMapsUrl }) => {
  const icons = []
  if (facebookUrl) {
    icons.push({
      id: "facebook",
      icon: <FaFacebook className="icon facebook-icon" />,
      path: facebookUrl,
      ariaLabel: "Find us on Facebook",
    })
  }

  if (instagramUrl) {
    icons.push({
      id: "instagram",
      icon: <FaInstagram className="icon instagram-icon" />,
      path: instagramUrl,
      ariaLabel: "Find us on Instagram",
    })
  }

  if (googleMapsUrl) {
    icons.push({
      id: "google-maps",
      icon: <FaMapMarkerAlt className="icon map-marker-icon" />,
      path: googleMapsUrl,
      ariaLabel: "Get Directions to Maar's Burger Bar",
    })
  }

  const block = "maars-footer"
  return (
    <div className={block}>
      <div className={`${block}__icons-container`}>
        {icons.map(({ id, path, ariaLabel, icon }) => (
          <OutboundLink
            key={id}
            href={path}
            aria-label={ariaLabel}
            target="_blank"
            rel="noopener noreferrer"
          >
            {icon}
          </OutboundLink>
        ))}
      </div>
      <p className={`${block}__copyright`}>
        © {new Date().getFullYear()} Maar's Burger Bar
      </p>
    </div>
  )
}

Footer.propTypes = {
  facebookUrl: PropTypes.string,
  instagramUrl: PropTypes.string,
  googleMapsUrl: PropTypes.string,
}

Footer.defaultProps = {
  facebookUrl: "",
  instagramUrl: "",
  googleMapsUrl: "",
}

export default Footer
