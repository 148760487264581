import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { RichText } from "prismic-reactjs"
import linkResolver from "../../utils/linkResolver"

const Text = ({
  size,
  text,
  htmlContent,
  align,
  color,
  marginTop,
  marginBottom,
}) => {
  const block = "maars-text"
  return (
    <div
      className={classNames(
        block,
        `${block}__size-${size}`,
        `${block}__color-${color}`,
        ` ${block}__align-${align}`,
        { [`${block}__margin-top-${marginTop}`]: marginTop },
        { [`${block}__margin-bottom-${marginBottom}`]: marginBottom }
      )}
    >
      {text && text}
      {htmlContent && htmlContent.length > 0 && (
        <RichText render={htmlContent} linkResolver={linkResolver} />
      )}
    </div>
  )
}

Text.propTypes = {
  size: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  align: PropTypes.string,
  htmlContent: PropTypes.array,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
}

Text.defaultProps = {
  size: "medium",
  text: "",
  color: "black",
  align: "left",
}

export default Text
