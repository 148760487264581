import React from "react"
import { Button, Header, Text, Carousel } from "components"
import { useStaticQuery, graphql } from "gatsby"

const About = () => {
  const { prismicAboutUsPage } = useStaticQuery(
    graphql`
      query AboutUsPage {
        prismicAboutUsPage {
          data {
            text {
              raw
            }
            instagram_url {
              url
            }
            gallery {
              image {
                url
                alt
              }
            }
          }
        }
      }
    `
  )

  const aboutUsTextHtml =
    prismicAboutUsPage.data &&
    prismicAboutUsPage.data.text &&
    prismicAboutUsPage.data.text.raw
  const instagramUrl =
    prismicAboutUsPage.data &&
    prismicAboutUsPage.data.instagram_url &&
    prismicAboutUsPage.data.instagram_url.url

  const gallery = prismicAboutUsPage.data && prismicAboutUsPage.data.gallery
  const block = "maars-about"
  return (
    <div className={block}>
      <Header
        size="h2"
        headerStyle="h2"
        align="center"
        color="black"
        text="About Us"
        marginBottom="medium"
      />

      <Text
        size="large"
        color="black"
        align="left"
        marginBottom="large"
        htmlContent={aboutUsTextHtml}
      />

      <Carousel images={gallery} />
      <div className={`${block}__button-container `}>
        <Button
          buttonStyle="primary-light"
          link={instagramUrl}
          label="See More..."
        />
      </div>
      <br />
      <br />
    </div>
  )
}

export default About
