import React, { useState } from "react"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { Button, AnimatedOverlay } from "components"
import PropTypes from "prop-types"

const orderButton = {
  id: "order",
  title: "Order Now",
  analyticCategory: "Online Order Button",
}

const navigationButtons = [
  { id: "menu", title: "Menu", analyticCategory: "Menu Button" },
  { id: "about", title: "About", analyticCategory: "About Button" },
  { id: "info", title: "Info", analyticCategory: "Info Button" },
]

const Navigation = ({ onlineStoreUrl }) => {
  const [overlay, setOverlay] = useState("none")
  /* Browser Targetting: https://stackoverflow.com/questions/49328382/browser-detection-in-reactjs */
  // const isFirefox = typeof InstallTrigger !== "undefined"
  // const showUnanimatedVersion = isFirefox

  const block = "maars-navigation"
  return (
    <React.Fragment>
      {/* {showUnanimatedVersion ? ( */}
      {/* <Overlay
          closeOverlay={() => {
            setOverlay("none")
          }}
          overlay={overlay}
        />
      ) : ( */}
      <AnimatedOverlay
        closeOverlay={() => {
          setOverlay("none")
        }}
        overlay={overlay}
      />
      {/* )} */}

      <div className={block} role="navigation">
        <div className={`${block}__order-button-wrapper`}>
          <Button
            link={onlineStoreUrl}
            label={orderButton.title}
            buttonStyle="navigation-dark"
            className="order-button"
          />
        </div>

        <div className={`${block}__buttons-container`}>
          {navigationButtons &&
            navigationButtons.map(({ id, title, analyticCategory }) => {
              return (
                <Button
                  key={id}
                  onClick={() => {
                    trackCustomEvent({
                      category: analyticCategory,
                      action: "Click",
                    })
                    setOverlay(id)
                  }}
                  label={title}
                  buttonStyle="navigation-dark"
                />
              )
            })}
        </div>
      </div>
    </React.Fragment>
  )
}

Navigation.propTypes = {
  onlineStoreUrl: PropTypes.string,
}

Navigation.defaultProps = {
  onlineStoreUrl: "",
}

export default Navigation
