import React from "react"
import { FaDirections } from "react-icons/fa"
import { Button, Header } from "components"
import { useStaticQuery, graphql } from "gatsby"

const parseContactInfo = data => {
  const email = data.email && data.email.text
  const phoneNumber = data.phone_number && data.phone_number.text
  const googleMapsUrl = data.google_maps_url && data.google_maps_url.url
  const addressOne = (data.address_one && data.address_one.text) || ""
  const city = (data.city && data.city.text) || ""
  const province = (data.province && data.province.text) || ""
  const zipCode = (data.zipCode && data.zipCode.text) || ""
  const businessHours = [
    { Monday: data.monday_hours },
    { Tuesday: data.tuesday_hours },
    { Wednesday: data.wednesday_hours },
    { Thursday: data.thursday_hours },
    { Friday: data.friday_hours },
    { Saturday: data.saturday_hours },
    { Sunday: data.sunday_hours },
  ]
  return {
    googleMapsUrl,
    phoneNumber,
    email,
    businessHours,
    addressOne,
    city,
    province,
    zipCode,
  }
}

const Info = () => {
  const { prismicContactUs } = useStaticQuery(
    graphql`
      query {
        prismicContactUs {
          data {
            email {
              text
            }
            phone_number {
              text
            }
            google_maps_url {
              target
              url
            }
            address_one {
              text
            }
            city {
              text
            }
            province {
              text
            }
            zip_code {
              text
            }
            monday_hours
            tuesday_hours
            wednesday_hours
            thursday_hours
            friday_hours
            saturday_hours
            sunday_hours
          }
        }
      }
    `
  )

  const contactInfo = prismicContactUs.data
  const {
    email,
    googleMapsUrl,
    phoneNumber,
    businessHours,
    addressOne,
    city,
    province,
    zipCode,
  } = parseContactInfo(contactInfo)

  const block = "maars-info"
  return (
    <div className={block}>
      <Header
        size="h2"
        headerStyle="h2"
        align="center"
        color="black"
        marginBottom="medium"
        text="Location"
      />
      <Header
        size="h3"
        headerStyle="h3"
        align="center"
        color="black"
        marginBottom="small"
        text={`${addressOne}, ${city}, ${province}, ${zipCode}`}
      />

      <div className={`${block}__button-container`}>
        <Button
          buttonStyle="primary-light"
          label="Get Directions"
          link={googleMapsUrl}
          className="margin-bottom-none"
        >
          <FaDirections />
        </Button>
      </div>

      <div className={`${block}__hours-section`}>
        <Header
          size="h2"
          headerStyle="h2"
          align="center"
          color="black"
          text="Our Hours"
          marginBottom="medium"
          marginTop="large"
        />

        <div className={`${block}__business-hours`}>
          {businessHours.map(dayObj => {
            const day = Object.keys(dayObj)[0]
            const time = dayObj[day]
            return (
              <div key={day}>
                <h3>
                  {day}: {time}
                </h3>
              </div>
            )
          })}
        </div>
      </div>

      <Header
        size="h2"
        headerStyle="h2"
        align="center"
        color="black"
        text="Contact Us"
        marginBottom="medium"
        marginTop="large"
      />

      <div className={`${block}__contact-info-container`}>
        <Button
          buttonStyle="primary-light"
          link={`tel:+1${phoneNumber.replace(/\D+/g, "")}`}
          label={phoneNumber}
        />
        <Button
          buttonStyle="primary-light"
          link={`mailto: ${email}`}
          label={email}
          className="text-overflow-ellipsis"
        />
      </div>
    </div>
  )
}

export default Info
