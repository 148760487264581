import React from "react"
// import Img from "gatsby-image"
// import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

// const GET_IMAGES = graphql`
//   {
//     allInstaNode(sort: { order: DESC, fields: timestamp }, limit: 12) {
//       edges {
//         node {
//           id
//           localFile {
//             childImageSharp {
//               fluid(maxWidth: 700) {
//                 ...GatsbyImageSharpFluid
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `

/*  NON Instagram Photos */
const Carousel = ({ maxNumOfImages, images }) => {
  const block = "maars-carousel"
  return (
    <div className={block}>
      <Slider
        dots={true}
        infinite={true}
        speed={500}
        arrows={false}
        slidesToScroll={1}
        slidesToShow={1}
        autoplay={true}
      >
        {images.map(({ image }, index) => {
          if (index >= maxNumOfImages) return false
          return (
            <img
              key={index}
              src={image.url}
              alt={image.alt || ""}
              width="100%"
              className={`${block}__image`}
            />
          )
        })}
      </Slider>
    </div>
  )
}

// const Carousel = ({ maxNumOfImages }) => {
//   const block = "maars-carousel"
//   return (
//     <StaticQuery
//       query={GET_IMAGES}
//       render={data => {
//         const images = data && data.allInstaNode && data.allInstaNode.edges
//         if (images) {
//           return (
//             <div className={block}>
//               <Slider
//                 dots={true}
//                 infinite={true}
//                 speed={500}
//                 arrows={false}
//                 slidesToScroll={1}
//                 slidesToShow={1}
//                 autoplay={true}
//               >
//                 {images.map(({ node }, index) => {
//                   if (index >= maxNumOfImages) return false
//                   return (
//                     <Img
//                       key={node.id}
//                       fluid={node.localFile.childImageSharp.fluid}
//                       width="100%"
//                       className={`${block}__image`}
//                     />
//                   )
//                 })}
//               </Slider>
//             </div>
//           )
//         }
//       }}
//     />
//   )
// }

Carousel.propTypes = {
  maxNumOfImages: PropTypes.number,
}

Carousel.defaultProps = {
  maxNumOfImages: 6,
}

export default Carousel
