import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const parseSeoData = ({ data = {} }) => {
  const metaTitle = data.title && data.title.text
  const metaDescription = data.description && data.description.text
  const metaKeywords = data.keywords
  const imageSrc = data.site_image && data.site_image.url
  const imageAlt = data.site_image && data.site_image.alt
  const { height: imageHeight, width: imageWidth } =
    data.site_image && data.site_image.dimensions

  return {
    metaTitle,
    metaDescription,
    metaKeywords,
    imageSrc,
    imageAlt,
    imageHeight,
    imageWidth,
  }
}

const SEO = ({ lang, title, description }) => {
  const { prismicSeo } = useStaticQuery(
    graphql`
      query seoQuery {
        prismicSeo {
          data {
            keywords {
              keyword
            }
            site_image {
              url
              alt
              dimensions {
                height
                width
              }
            }
            title {
              text
            }
            description {
              text
            }
          }
        }
      }
    `
  )

  const {
    metaTitle,
    metaDescription,
    metaKeywords,
    imageSrc,
    imageAlt,
    imageHeight,
    imageWidth,
  } = parseSeoData(prismicSeo)

  title = title || metaTitle
  description = description || metaDescription

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: "og:image",
          content: imageSrc,
        },
        {
          property: "og:image:width",
          content: imageWidth,
        },
        {
          property: "og:image:height",
          content: imageHeight,
        },
        {
          name: `twitter:card`,
          content: `summary`, // MUST be set to a value of "summary"
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          //Images MUST BE 1:1 AR. min: 144x144px & max: 4096x4096px
          name: `twitter:image`,
          content: imageSrc,
        },
        {
          name: `twitter:image:alt`,
          content: imageAlt,
        },
        {
          name: `twitter:description`,
          content: description,
        },
        {
          name: "keywords",
          content: metaKeywords.map(obj => obj.keyword).join(","),
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: "en",
  title: "Maar's Burger Bar",
  description: "",
}

SEO.propTypes = {
  lang: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
}

export default SEO
